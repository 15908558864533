.timeTrackingTitle {
  font-weight: 700;
}

.platformButton {
  display: flex;
  justify-content: space-evenly;
  padding: 42px !important;
}

.paraPlatform {
  color: #707070;
}

.editIcons {
  display: flex;
  justify-content: end;
  margin-top: -19px !important;
}
.MuiSelect-select:focus {
  /* background-color: transparent !important; */
}
