@import "../../../CommonStyles.scss";

.bottom-appbar {
  height: 55px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  .bottomTabs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;

    .bottomTab {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #888; /* Default color for inactive tabs */
      font-size: 12px;
      background-color: #ffffff !important;

      .tab-icon {
        font-size: 20px;
        color: #888; /* Inactive icon color */
      }

      .tab-label {
        margin-top: 4px;
        font-size: 10px;
        font-weight: 400;
        font-family: "Roboto, sans-serif";
        line-height: 11.72px;
      }

      /* Active Tab Styles */
      &.is-active {
        background-color: white; /* Color for active tab */
        position: relative;

        .tab-icon {
          color: white;
          background-color: #007e63;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          border-radius: 50%;
          position: absolute;
          top: -0.4rem;
        }

        .tab-label {
          margin-top: 4px;
          font-size: 10px;
          color: #007e63;
          font-weight: 500;
          font-family: "Roboto, sans-serif";
          line-height: 11.72px;
          position: absolute;
          bottom: 5px;
        }
      }
    }
  }

  .elearningtab:first-child {
    border-top-right-radius: 15px;
  }

  .elearningtab:last-child {
    border-top-left-radius: 15px;
  }
}

